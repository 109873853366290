import React from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import styles from './TextOnly.module.scss';
import iwocaPageStyles from '../../uk/iwocaPage.module.scss';
import MetaData from '../../../components/Layout/MetaData';
import NavBar from '../../../components/NavBar/NavBar';
import Footer from '../../../components/Footer/Footer';

const TextOnly = ({ data }) => {
    const { contentfulPageTextOnly: pageData } = data;
    const {
        title,
        metaDescription,
        allowSearchEngineScraping = true,
        url,
        textContent
    } = pageData;

    return (
        <div className={iwocaPageStyles.Page}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />

            <NavBar />

            <div className={styles.wrapper}>
                {textContent.map((item) => {
                    switch (item.__typename) {
                        case 'ContentfulSectionTextOnly':
                            return documentToReactComponents(item.text.json);
                        default:
                            return null;
                    }
                })}
            </div>
            <Footer />
        </div>
    );
};

export const query = graphql`
    query TextOnlyPageQuery($id: String!) {
        contentfulPageTextOnly(id: { eq: $id }) {
            title
            url
            metaDescription
            allowSearchEngineScraping
            textContent {
                __typename
                ... on Node {
                    ... on ContentfulSectionTextOnly {
                        text {
                            json
                        }
                    }
                }
            }
        }
    }
`;

export default TextOnly;
